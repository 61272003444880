<template>
    <div class="body_bg">
        <v-snackbar
                timeout="2000"
                :value="snackbarShow"
                color="#F44336"
                absolute
                top
                v-model="snackbarShow"
        >{{ snackbarText }}
        </v-snackbar>
        <div class="container">
            <div class="left">
                <div class="l_title">
                    <v-icon large color="#1989FA">mdi-home-city-outline</v-icon>
                    <div>闲置租赁场所风险管控系统</div>
                </div>
                <img src="~assets/img/home/img.png">
            </div>
            <div class="right">
                <div class="r_title">
                    <div>找回密码</div>
                </div>
                <div class="r_input">
                    <div style="margin-bottom: 15px">
                        <el-input placeholder="请输入手机号">
                            <template #prepend>手机号</template>
                        </el-input>
                    </div>

                    <div style="margin-bottom: 15px;display:flex; align-content: center">
                        <el-input placeholder="验证码">
                            <template #prepend>验证码</template>
                        </el-input>
                        <el-button style="margin-left: 5px;">获取验证码</el-button>
                    </div>

                    <div style="margin-bottom: 15px">
                        <el-input placeholder="请输入新密码">
                            <template #prepend>新密码</template>
                        </el-input>
                    </div>

                    <div style="margin-bottom: 15px">
                        <el-input placeholder="再次输入新密码">
                            <template #prepend>确认密码</template>
                        </el-input>
                    </div>
                </div>
                <div class="r_bt text-center">
                    <v-btn
                            depressed
                            color="#E6F1FC"
                            width="88%"
                            height="40px"
                            dark
                            style="font-weight: bold;font-size: 16px;color:#1989FA "
                    >确认
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "FindPwd",
        data() {
            return {
                //密码显示
                show: false,
                //账号
                loginMsg: {
                    username: '',
                    password: '',
                },
                //提示
                snackbarShow: false,
                snackbarText: '',
            }
        },
        methods: {
            //跳转到注册
            toSignUp() {
                this.$router.push('/signup')
            }

        }
    }
</script>

<style lang="less" scoped>
    .body_bg {
        position: absolute;
        left: 0;
        top: 0px;
        width: 100%;
        height: 100%;
        //background-image: linear-gradient(-225deg, #D4FFEC 0%, #57F2CC 48%, #4596FB 100%);

    }

    .container {
        width: 691px;
        height: 411px;
        border-radius: 21px;
        background: #ffffff;
        box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.31);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 15px;
        display: flex;
        flex-direction: row;

        .left {
            height: 360px;
            width: 330px;

            .l_title {
                height: 60px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                text-align: center;
                line-height: 60px;
                font-weight: bold;
                justify-content: space-evenly;
            }

            img {
                width: 280px;
                height: 300px;
                margin-left: 25px
            }
        }

        .right {
            height: 365px;
            width: 340px;
            border: #F6F6F6 1px solid;
            display: flex;
            flex-direction: column;

            .r_title {
                height: 60px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                //text-align: center;
                line-height: 40px;
                margin-top: 35px;
                font-weight: bold;
                justify-content: space-evenly;
            }

            .r_input {
                margin-top: 20px;
                width: 90%;
                margin-left: 6%;

                .r_input_text {
                    margin-top: 5px;
                    width: 90%;
                    height: 18px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-left: 5%;
                }
            }

            .r_bt {
                display: flex;
                justify-content: center;
                margin-top: 10px;
            }

            .r_registered {
                margin-top: 10px;
                text-align: center;
                color: #666666;
                font-size: 13px;
            }

        }
    }
</style>
